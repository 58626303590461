/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { useStaticQuery, graphql, Link } from 'gatsby'

const Container = styled.div`
  width: 100%;
  background: black;
  color: white;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding: 36px 0;
  display: flex;
  justify-content: center;

  & a {
    color: #cab996 !important;
  }

  & a:hover {
    color: #cab996 !important;
    text-decoration: underline;
  }
`
const Wrapper = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`
const Title = styled.h5`
  font-size: 16px;
  line-height: 19px;
  padding: 0 8px 18px 8px;
  border-bottom: 3px solid #cab996;
  margin-bottom: 54px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 16px;
    padding: 0 6px 16px 6px;
    border-bottom: 2px solid #cab996;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 16px;
    padding: 0 4px 14px 4px;
    border-bottom: 2px solid #cab996;
    margin-bottom: 30px;
  }
`
const Column1 = styled.div`
  width: 265px;
  margin: 65px 20px 0 20px;
  @media screen and (max-width: 991px) {
    width: calc(100% - 60px);
    margin: 40px auto 20px auto;
  }
`
const MenuItem = styled(Link)`
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  line-height: 35px;
  display: block;
  width: fit-content;
  margin: 0 auto;
  &:hover {
    text-decoration: none;
    color: white;
  }
  @media screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 28px;
  }
`
const Column2 = styled.div`
  border: 2px solid white;
  width: 410px;
  padding: 66px 28px 100px 28px;
  @media screen and (max-width: 991px) {
    width: calc(100% - 60px);
    margin: 40px auto 20px auto;
    padding: 46px 18px 60px 18px;
  }
`
const Address = styled.div`
  font-size: 14px;
  line-height: 35px;
  color: white;
  text-align: center;

  & a {
    color: #cab996;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 28px;
  }
`

const Footer = () => {
  const { wordpressWpComponenten: edges } = useStaticQuery(graphql`
    {
      wordpressWpComponenten(wordpress_id: { eq: 138 }) {
        acf {
          menu_1 {
            title
            menu {
              button {
                title
                url
              }
            }
          }
          menu_2 {
            title
            menu_1 {
              title
              menu {
                button {
                  title
                  url
                }
              }
            }
            menu_2 {
              title
              menu {
                button {
                  title
                  url
                }
              }
            }
          }
          menu_3 {
            title
            description
            button {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper>
        <Column1>
          <Title>{edges.acf.menu_1.title}</Title>
          {edges.acf.menu_1.menu.map(({ button }) => {
            return (
              <MenuItem key={button.url} to={button.url}>
                {button.title}
              </MenuItem>
            )
          })}
        </Column1>
        <Column2>
          <Title>{edges.acf.menu_2.title}</Title>
          <div className="d-flex text-left justify-content-between">
            <Services
              title={edges.acf.menu_2.menu_1.title}
              items={edges.acf.menu_2.menu_1.menu}
            />
            <Services
              title={edges.acf.menu_2.menu_2.title}
              items={edges.acf.menu_2.menu_2.menu}
            />
          </div>
        </Column2>
        <Column1>
          <Title>{edges.acf.menu_3.title}</Title>
          <Address>{parse(edges.acf.menu_3.description)}</Address>
        </Column1>
      </Wrapper>
    </Container>
  )
}

export default Footer

const ServiceItem = styled(Link)`
  font-size: 14px;
  line-height: 35px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  &:first-child:before {
    width: 0;
    height: 0;
    display: block;
  }
  &:first-child {
    display: block;
  }
  @media screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 28px;
  }
`
const Services = ({ title, items }) => {
  return (
    <div className="text-center d-flex flex-column">
      <span className='mb-2'>{title}</span>
      {items.map(({ button }) => {
        return (
          <ServiceItem key={button.url} to={button.url}>
            <span>{button.title}</span>
          </ServiceItem>
        )
      })}
    </div>
  )
}
