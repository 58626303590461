/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'
import CustomLink from './CustomLink'

const Content = styled.div`
  & ul {
    & li {
    }
  }

  & a {
    color: #cab996;
  }

  & img {
  }
`

const ParseContent = ({ content, className }) => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if (site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  if (!content) {
    return ''
  }

  return (
    <Content className={className}>
      {parse(content, {
        replace: (domNode) => {
          if (domNode.name === 'img') {
            const attributes = domNode.attribs

            attributes.src = `${sourceUrl}${domNode.attribs.src}`
            attributes.className = attributes.class

            delete attributes.class
            delete attributes.srcset

            if (attributes.style) {
              delete attributes.style
            }

            return <img {...attributes} />
          }

          if (domNode.name === 'a') {
            const attributes = domNode.attribs

            if (attributes.style) {
              delete attributes.style
            }

            if (
              attributes.href.indexOf('www.') !== -1 ||
              attributes.href.indexOf('http:') !== -1 ||
              attributes.href.indexOf('https:') !== -1
            ) {
              return (
                <a {...attributes} target="_blank">
                  {domToReact(domNode.children, {})}
                </a>
              )
            }

            if (
              attributes.href.indexOf('tel:') ||
              attributes.href.indexOf('mailto:')
            ) {
              return <a {...attributes}>{domToReact(domNode.children, {})}</a>
            }

            return (
              <CustomLink to={attributes.href}>
                {domToReact(domNode.children, {})}
              </CustomLink>
            )
          }
        },
      })}
    </Content>
  )
}

export default ParseContent
