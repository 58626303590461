/* eslint-disable import/no-unresolved */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

// Language
// eslint-disable-next-line import/extensions
import { getLanguage } from 'services/language'

// Libraries
import { Helmet } from 'react-helmet'

// Icon
import favicon from 'img/favicon.png'

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultKeywords: keywords
      }
    }
    file(relativePath: { eq: "favicon.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 100, quality: 100) {
          src
        }
      }
    }
  }
`

function SEO({
  title,
  description,
  image,
  pathname,
  article,
  keywords,
  yoast,
}) {
  const language = getLanguage()

  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultKeywords,
            defaultTitle,
            defaultDescription,
            siteUrl,
          },
        },
        file: featuredImage,
      }) => {
        let seoTitle = title
        let seoDescription = description

        if (yoast) {
          const yoastTitleObj = yoast.meta.find(
            ({ property }) => property === 'og:title'
          )
          const yoastDescriptionObj = yoast.meta.find(
            ({ name }) => name === 'description'
          )

          const yoastTitle =
            yoastTitleObj &&
            Object.keys(yoastTitleObj).length > 0 &&
            yoastTitleObj.content
              ? yoastTitleObj.content
              : defaultTitle
          const yoastDescription =
            yoastDescriptionObj &&
            Object.keys(yoastDescriptionObj).length > 0 &&
            yoastDescriptionObj.content
              ? yoastDescriptionObj.content
              : defaultDescription

          seoTitle = title || yoastTitle
          seoDescription = description || yoastDescription
        }

        const seo = {
          title: seoTitle || defaultTitle,
          description: seoDescription || defaultDescription,
          image: image
            ? `${siteUrl}${image}`
            : `${siteUrl}${featuredImage.childImageSharp.resize.src}`,
          url: `${siteUrl}${pathname || '/'}`,
          keywords: keywords || defaultKeywords,
        }

        if (typeof document !== 'undefined') {
          const titleHtml = seo.title
          const div = document.createElement('div')
          div.innerHTML = titleHtml
          seo.title = div.innerText
        }

        return (
          <>
            <Helmet title={seo.title}>
              <html lang="nl" />
              <link rel="icon" href={favicon} />
              <meta name="description" content={seo.description} />
              <meta name="keywords" content={seo.keywords} />
              <meta name="image" content={seo.image} />
              <meta name="format-detection" content="telephone=no" />

              <meta name="og:site_name" content={defaultTitle} />
              {seo.url && <meta property="og:url" content={seo.url} />}
              {(article ? true : null) && (
                <meta property="og:type" content="article" />
              )}
              {seo.title && <meta property="og:title" content={seo.title} />}
              {seo.description && (
                <meta property="og:description" content={seo.description} />
              )}
              {seo.image && (
                <meta
                  property="og:image"
                  itemProp="image"
                  content={seo.image}
                />
              )}
              <meta name="twitter:card" content="summary_large_image" />
              {seo.title && <meta name="twitter:title" content={seo.title} />}
              {seo.description && (
                <meta name="twitter:description" content={seo.description} />
              )}
              {seo.image && <meta name="twitter:image" content={seo.image} />}
              {language === 'nl_NL'
                ? [
                    <meta key={0} property="og:locale" content="nl_NL" />,
                    <meta
                      key={1}
                      property="og:locale:alternate"
                      content="en_GB"
                    />,
                  ]
                : [
                    <meta key={0} property="og:locale" content="en_GB" />,
                    <meta
                      key={1}
                      property="og:locale:alternate"
                      content="nl_NL"
                    />,
                  ]}
              <script
                id="Cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                data-cbid="336cfc99-fcfb-4dfb-8679-a0c5043919e6"
                type="text/javascript"
                async
              />
            </Helmet>
          </>
        )
      }}
    />
  )
}

export default SEO
