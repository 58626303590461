import React from 'react'
import styled from 'styled-components'

import ImageBackground from 'components/elements/ImageBackground'

import parse from 'html-react-parser'
import { Button } from '../elements'

const Container = styled(ImageBackground)`
  max-width: 1920px;
  margin: 0 auto !important;
  height: 440px;
  width: 100%;
  display: flex;
  padding: 40px calc(50% - 530px) 40px calc(50% - 530px);
  @media screen and (max-width: 1060px) {
    height: 320px;
    padding: 30px calc(50% - 384px) 30px calc(50% - 384px);
  }
  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => (props.type ? 'column' : 'row')};
  }
`
const TitleWrapper = styled.div`
  width: 400px;
  height: 400px;
  border: 8px solid white;
  color: white;
  text-transform: uppercase;
  margin-right: auto;
  display: flex;
  text-align: left;
  transition: all 300ms ease;
  padding: 42px 40px;
  background: linear-gradient(
    transparent 0%,
    #d6cab065 15%,
    rgba(0, 0, 0, 0.6) 35%,
    rgba(0, 0, 0, 0.75) 70%,
    rgba(0, 0, 0, 0.9) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: 2000px) {
    width: unset;
    height: unset;
    min-width: 500px;
    min-height: 360px;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    @media (min-width: 992px) {
      font-size: 22px;
      line-height: 35px;
    }
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  & p {
    color: white;
  }

  & a {
    color: #cab996;
    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
    height: 100%;
    border: 6px solid white;
    font-size: 24px;
    line-height: 28px;
    padding: 28px 24px;
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {
    width: 270px;
    height: 240px;
    margin: auto auto;
    font-size: 16px;
    line-height: 22px;
  }
`
const ButtonWrapper = styled.div`
  margin-top: auto;
  @media screen and (max-width: 768px) {
    margin: 20px auto 0 auto;
  }
`
const Hero2 = ({ backgroundImg, title, button, aboveFold }) => {
  return (
    <Container src={backgroundImg} type={button ? 1 : 0} aboveFold={aboveFold}>
      <TitleWrapper>{parse(title)}</TitleWrapper>
      {button && (
        <ButtonWrapper>
          <Button type={1} label={button.title} to={button.url} />
        </ButtonWrapper>
      )}
    </Container>
  )
}
export default Hero2
