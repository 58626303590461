import React from 'react'
import styled from 'styled-components'
import Button from 'components/elements/Button'
import ParseContent from 'components/shared/ParseContent'

const Container = styled.div`
  background: #f1f1f1;
  width: 100%;
  text-align: center;
  color: black;
  font-size: 20px;
  line-height: 24px;
  padding: 20px 60px 40px 60px;
  @media screen and (max-width: 1060px) {
    font-size: 16px;
    line-height: 20px;
    padding: 20px 60px 20px 60px;
  }
`

const CTA = ({ text, button }) => {
  return (
    <Container>
      <ParseContent content={text} />
      <div className="d-flex justify-content-center">
        <Button to={button.url} label={button.title} />
      </div>
    </Container>
  )
}
export default CTA
