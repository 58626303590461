import React from 'react'
import styled from 'styled-components'
import { Button, Heading } from 'components/elements'

import ParseContent from 'components/shared/ParseContent'

const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 64px;
  transition: all 300ms ease;
  @media screen and (max-width: 1060px) {
    margin-top: 64px;
    margin-bottom: 40px;
  }
`
const Description = styled(ParseContent)`
  width: 925px;
  color: black;
  font-size: 16px;
  line-height: 30px;
  transition: all 300ms ease;
  text-align: center;
  margin-bottom: 46px;
  @media screen and (max-width: 1060px) {
    width: 678px;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 34px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 34px;
    padding: 0 30px;
  }
`
export default function Article({ title, description, button }) {
  return (
    <Container>
      <Heading text={title} />
      <Description className="mx-auto" content={description} />
      <div className="d-flex justify-content-center">
        <Button
          className="mx-auto"
          label={button.title}
          shadowColor="#D8D8D8"
          to={button.url}
        />
      </div>
    </Container>
  )
}
