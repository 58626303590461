/* eslint-disable eqeqeq */
import React from 'react'
import styled from 'styled-components'

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 27px;
  margin: 6px 0px 12px 0;
  cursor: pointer;
  color: ${(props) => (props.type == 0 ? '#cab996' : 'black')};
  font-size: ${(props) => (props.type == 0 ? '14px' : '12px')};
  line-height: ${(props) => (props.type == 0 ? '16px' : '14px')};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  a {
    color: black;
    text-decoration: underline;
  }
  @media screen and (max-width: 1060px) {
    padding-left: 20px;
    font-size: ${(props) => (props.type == 0 ? '10px' : '9px')};
    line-height: ${(props) => (props.type == 0 ? '12px' : '10px')};
  }
`

const CheckMark = styled.span`
  position: absolute;
  top: -15px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #979797;
  background-color: white;
  margin-top: ${(props) => (props.type == 0 ? 0 : '5px')};
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
  ${Container}:hover input ~ & {
    background-color: #ccc;
  }
  ${Container} input:checked ~ & {
    background-color: #979797;
  }
  ${Container} input:checked ~ &:after {
    display: block;
  }
  ${Container} &:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media screen and (max-width: 1060px) {
    height: 11px;
    width: 11px;
    ${Container} &:after {
      left: 3px;
      top: 1px;
      width: 3px;
      height: 6px;
      border: solid white;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`

export default function CheckBox({
  value = false,
  onChange,
  name,
  type,
  id,
  required,
  placeholder,
  children,
}) {
  return (
    <Container type={type}>
      {children}
      <input
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        id={id}
        required={required}
        placeholder={placeholder}
      />
      <CheckMark type={type} />
    </Container>
  )
}
