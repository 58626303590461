import React from 'react'
import styled from 'styled-components'
import { FaWhatsapp } from 'react-icons/fa'

const Container = styled.a`
  width: 80px;
  height: 80px;
  border: 5px solid white;
  background: #cab996;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  transition: all 300ms ease;
  position: fixed;
  z-index: 4;
  bottom: 30px;
  right: 30px;
  & > svg {
    margin-left: auto;
    margin-right: auto;
  }
  &:hover {
    border: 5px solid #cab996;
    background: white;
  }
  cursor: pointer;
  @media screen and (max-width: 1060px) {
    width: 60px;
    height: 60px;
    border: 3px solid white;

    & > svg {
      width: 30px;
      height: 30px;
    }
    &:hover {
      border: 3px solid #cab996;
      background: white;
    }
  }
`
export default function WhatsappButton() {
  return (
    <Container href="https://wa.link/9wxs3s" target="_blank">
      <FaWhatsapp color="black" size={40} />
    </Container>
  )
}
