import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

import TriangleImg from 'img/triangle.png'
import TriangleImg2 from 'img/triangle_black.png'
import { Link } from 'gatsby'

const Wrapper = styled(Link)`
  position: relative;
  z-index: 9999;
  width: fit-content;
  height: 70px;
  @media screen and (max-width: 1060px) {
    height: 50px;
  }
`
const Container = styled.div`
  height: 60px;
  background: ${(props) => (props.type === 0 ? 'black' : '#CAB996')};
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 19px;
  font-size: 16px;
  text-align: left;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  z-index: 1;
  border: ${(props) =>
    props.type === 0 ? '5px solid black' : '5px solid #CAB996'};
  transition: all 300ms ease;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media screen and (max-width: 1060px) {
    height: 44px;
    padding: 0 18px;
    line-height: 15px;
    font-size: 12px;
    border: ${(props) =>
      props.type === 0 ? '3px solid black' : '3px solid #CAB996'};
  }
`
const Text = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 40px;
  @media screen and (max-width: 1060px) {
    margin-right: 30px;
  }
`
const Icon = styled.div`
  background: url(${(props) => props.img}) no-repeat center;
  background-size: contain;
  width: 20px;
  height: 22px;
  margin-top: auto;
  margin-bottom: auto;
  @media screen and (max-width: 1060px) {
    width: 16px;
    height: 18px;
  }
`
const Shadow = styled.div`
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  width: 100%;
  height: 60px;
  border: ${(props) => `5px solid ${props.color}`};
  z-index: -1;
  @media screen and (max-width: 1060px) {
    margin-top: 6px;
    margin-left: 6px;
    width: 100%;
    height: 44px;
    border: ${(props) => `3px solid ${props.color}`};
  }
`
export default function Button({ label, shadowColor, to, type = 0 }) {
  return (
    <Wrapper to={to}>
      {!!shadowColor && <Shadow color={shadowColor} />}
      <Container type={type}>
        <Text>{parse(label)}</Text>
        <Icon img={type === 0 ? TriangleImg : TriangleImg2} />
      </Container>
    </Wrapper>
  )
}
