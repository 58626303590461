import styled from 'styled-components'

export default styled.a`
  width: 27px;
  height: 27px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
  transition: all 300ms ease;
  text-decoration: none;
  & > svg {
    margin-top: auto;
    margin-bottom: auto;
    color: black;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover {
    cursor: pointer;
    background: black;
    border: 1px solid white;
    & > svg {
      color: white;
    }
  }
`
