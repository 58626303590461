import React from 'react'
import styled from 'styled-components'

import parse from 'html-react-parser'

const Container = styled.div`
  margin-bottom: 32px;
`
const Text = styled.div`
  color: black;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  transition: all 300ms ease;
  margin-bottom: 20px;
  text-transform: uppercase;

  & h1, & h2, & h3 & h4 & h5 {
    font-size: 25px;
    line-height: 30px;
  }

  @media screen and (max-width: 1060px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
  }
`
const Border = styled.div`
  height: 3px;
  width: 200px;
  background: #cab996;
  margin-left: auto;
  transition: all 300ms ease;
  margin-right: auto;
  @media screen and (max-width: 1060px) {
    height: 2px;
    width: 150px;
  }
`
export default function Heading({ text }) {
  return (
    <Container>
      <Text>{parse(text)}</Text>
      <Border />
    </Container>
  )
}
