import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 12px 0 12px 0;
  width: 100%;
`

const InputContainer = styled.textarea`
  border: 1px solid #979797;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 30px;
  width: 100%;
  height: 130px;
  @media screen and (max-width: 1060px) {
    padding: 7px 14px;
    font-size: 9px;
    line-height: 21px;
  }
`
export default function TextArea({
  value,
  onChange,
  name,
  type,
  id,
  required,
  placeholder,
}) {
  return (
    <Container>
      {/* <Label text={label} /> */}
      <InputContainer
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        id={id}
        required={required}
        placeholder={placeholder}
      />
    </Container>
  )
}
