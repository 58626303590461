import React from 'react'
import styled from 'styled-components'

import ImageBackground from 'components/elements/ImageBackground'

import parse from 'html-react-parser'

const Container = styled(ImageBackground)`
  max-width: 1920px;
  margin: 0 auto !important;
  height: 600px;
  width: 100%;
  display: flex;
  @media screen and (max-width: 1060px) {
    height: 450px;
  }
  @media screen and (max-width: 768px) {
    height: 450px;
  }
`
const TitleWrapper = styled.div`
  border: 8px solid white;
  margin-left: auto;
  width: 490px;
  height: 490px;
  margin-right: calc(50% - 530px);
  margin-top: auto;
  margin-bottom: 60px;
  transition: all 300ms ease;
  padding: 36px 32px;
  display: flex;
  text-align: left;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 0.4192051820728291) 70%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    color: white;
    line-height: 60px;
    text-transform: uppercase;

    @media (min-width: 992px) {
      font-size: 50px;
    }
    @media (max-width: 991px) {
    }
    @media (max-width: 575px) {
      font-size: 22px;
      line-height: 26px;
    }
  }

  & p {
    color: white;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  & a {
    color: #cab996;
    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 991px) {
    width: 70%;
    height: auto;
    margin: auto auto;
    padding: 10px 8px;
  }
`
const Hero = ({ backgroundImg, title, aboveFold }) => {
  return (
    <Container src={backgroundImg} aboveFold={aboveFold}>
      <TitleWrapper>{parse(title)}</TitleWrapper>
    </Container>
  )
}
export default Hero
